/*
 * @Author: your name
 * @Date: 2020-11-04 22:09:19
 * @LastEditTime: 2021-02-01 03:49:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\login\index.js
 */
// {/* <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests"></meta> */}
import {
  saveAs
} from 'file-saver';
import {
  downLoad,
  removeLoad,
  loadChange
} from "./api";

import {
  mapState
} from "vuex";
import moment from 'moment';
import util from "./util";
import {
  env
} from "@/common/constant";
export default {
  name: "loadCenter",
  metaInfo: {
    meta: [
      !(env == 'dev' || env == 'test' || env == 'uat') ? {
        'http-equiv': 'Content-Security-Policy',
        'content': 'upgrade-insecure-requests'
      } : '',
    ]
  },
  data() {
    return {
      breadcrumbData: [{
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/center/loadCenter",
          name: "loadCenter",
          title: "下载中心"
        }
      ],
      siez: 0,
      siezs: 4,
      size: 'default',
      list: [],
      choseList: [],
      dataNumber: 0,
      downLodaList: [],
      downLodaListOld: [],
      checkAll: false,
      wordName: '',
      createdDateStart: '',
      createdDateEnd: '',
      obj: {
        fileCreater: '',
        systemName: 'XTW',
        fileName: '',
        fileType: '',
        createdDateStart: '',
        createdDateEnd: '',
        pageNum: 1,
        pageSize: 20
      },
      times: '最近一周',
      chosetimeshow: true,
      loadhave: true,
      pageLoad: false,
      nolistImg: require("@/assets/order/noList.png"),
      timer:null
    };
  },
  computed: {
    ...mapState({
      //处理后的筛选数据list
      filList: (state) => state.filtrate.filList,
      //筛选组件加载loadding
      filterLoadFlag: (state) => state.filtrate.isLoading,
      //用于查询列表的筛选配置字段
      filterConfigStr: (state) => state.filtrate.filterConfigStr,
      isModelShow: (state) => state.user.isModelShow,
    }),
  },
  watch:{
    list:{
      handler(newValue) {
        console.log('newValue',newValue)
        if(newValue.length>0){
          // 获取 正在生成中文件
          let arr = newValue.filter(it => it.downloadStatus==0)
          // 当前列表如果正在生成中文件长度大于0每十秒调用查询列表的接口 重置列表数据 和 请求的页数 还有总条数
          // 因为是追加数据 如果不这么写的话 数据展示会有问题
          if (arr.length>0) {
            clearInterval(this.timer)
            this.timer = setInterval(() => {
              this.dataNumber = 0
              this.list = []
              this.obj.pageNum=1
              this.dataLoad()
            }, 10000)

          }
        }
      },
      immediate:true,
      deep:true,
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  mounted() {

    this.obj.fileCreater = this.$store.state.user.userInfo.account.account;
    // this.dataLoad();
    // this.fun_date(-7);
    this.$store.commit("filtrate/SET_SIMULATE_DATA", util.windowInitList.systemConfigForm.paramList);
    this.$store.dispatch("filtrate/systemConfigFormItem");
  },

  methods: {
    downChange(url, name) {
      let data = {
        url
      }
      loadChange(data).then(res => {
        saveAs(res.data, name);
      })
    },
    loadeBatch() {
      if (this.downLodaListOld && this.downLodaListOld.length == 0) {
        this.$message.info('请先选择下载文件');
        return
      }
      var newArray = [];
      for (let item of this.downLodaListOld) {
        // saveAs(item.filePath, item.name);
        let data = {
          url: item.filePath
        }
        newArray.push(loadChange(data))
      }
      Promise.all(newArray).then(res => {
        for (let i = 0; i < res.length; i++) {
          saveAs(res[i].data, this.downLodaListOld[i].name)
        }
      })
    },
    onFilterChange(arg) {
      if (arg[0].type == 1 || arg[0].type == '') {
        this.siez = 0;
      } else {
        this.siez = 2
        this.dataNumber = 0;
      }
      this.obj.pageNum = 1;
      this.loadhave = true;
      var arr = arg[0].timeFrame.split(',');
      if (arr[0] != '') {
        if (arr[0] == '1') {
          this.fun_date(-7);
        } else {
          this.fun_date(-30);
        }
      } else {
        this.obj.createdDateStart = arr[1];
        this.obj.createdDateEnd = arr[2];
        this.dataLoad();
      }

    },
    chosetime() {
      this.chosetimeshow = false;
      this.siez = 0;
    },
    // 不可选时间
    disabledDate(current) {
      // Can not select days before today and today
      return current && current > moment().endOf('day');
    },
    fun_date(num) {
      // var date1 = new Date();
      // //今天时间
      // var time1 = date1.getFullYear() + "-" + date1.getMonth() + 1 + "-" + date1.getDate();
      // this.obj.createdDateEnd = time1;
      // var date2 = new Date(date1);
      // date2.setDate(date1.getDate() + num);
      // var yeufen = (date2.getMonth() + 1) < 10 ? '' + date2.getMonth() + 1 : date2.getMonth() + 1
      // //num是正数表示之后的时间，num负数表示之前的时间，0表示今天
      // var time2 = date2.getFullYear() + "-" + yeufen + "-" + date2.getDate();
      // this.obj.createdDateStart = time2;

      //今天时间
      this.obj.createdDateEnd = this.$moment(new Date()).format("YYYY-MM-DD");
      // num天前的时间
      this.obj.createdDateStart = this.$moment().subtract(-num, 'days').format("YYYY-MM-DD");
      this.dataLoad();
    },
    // 全选
    onChangeAll() {
      if (this.checkAll) {
        let newObj = {};
        let newArray = []
        for (let item of this.list) {
          item.checked = true;
          newObj = {
            id: item.idStr,
            name: item.fileName,
            filePath: item.filePath
          }
          newArray.push(newObj);
          this.downLodaList.push(item.idStr)
        }
        this.downLodaListOld = newArray;
      } else {
        for (let item of this.list) {
          item.checked = false;
        }
        this.downLodaListOld = [];
        this.downLodaList = [];
      }
    },
    dataLoad() {
      let params = this.obj;
      this.pageLoad = true;
      downLoad(params).then(res => {
        this.pageLoad = false;
        // 如果类型为全部或者文档进行一下操作 否则直接置空 数据和数量
        if(this.siez == 0) {
          if (res && res.data && res.data[0].rows && res.data[0].rows.length > 0) {
            if (this.obj.pageNum != 1) {
              this.list = this.list.concat(res.data[0].rows);
              if (res.data[0].rows.length == 0) {
                this.loadhave = false;
              }
              // 当前列表数据条数多于后端返回总条数
              if (this.list.length < res.data[0].total) {
                this.loadhave = true;
              } else {
                this.loadhave = false;
              }
            } else {
              this.list = res.data[0].rows;
              if (res.data[0].rows.length == res.data[0].total) {
                this.loadhave = false;
              }
              if (this.list.length < res.data[0].total) {
                this.loadhave = true;
              }

            }
            this.dataNumber = res.data[0].total;
          } else {
            // 正式环境下载中心只剩一条数据的时候 不走上边判断 之前没写下边逻辑
            this.list = res.data[0].rows;
            if (res.data[0].rows.length == res.data[0].total) {
              this.loadhave = false;
            }
            this.dataNumber = res.data[0].total;

          }
        } else {
          this.dataNumber = 0
          this.list = []
        }
      }).catch(() => {
        this.pageLoad = false;
      })
    },
    isActive(index) {
      return
    },

    loadeMore() {
      this.obj.pageNum++;
      this.dataLoad();
    },
    downloadFile(name, blob) {
      if (
        "download" in document.createElement("a") &&
        navigator.userAgent.indexOf("Edge") == -1
      ) {
        let elink = document.createElement("a");
        elink.download = name;
        elink.style.display = "none";
        elink.href = blob
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        navigator.msSaveBlob(blob, name);
      }
    },
    // 批量下载
    download(name, href) {
      var a = document.createElement('a')
      var e = document.createEvent('MouseEvents') // 创建鼠标事件对象
      e.initEvent('click', false, false) // 初始化事件对象
      a.href = href // 设置下载地址
      a.download = name // 设置下载文件名
      a.dispatchEvent(e) // 给指定的元素，执行事件click事件
    },
    onChangeImg(itme, e) {
      if (e.target.checked) {
        itme.type = true
      } else {
        itme.type = false;
      }
    },
    // 批量删除
    deleteall() {
      if (this.downLodaListOld && this.downLodaListOld.length == 0) {
        this.$message.info('请先选择文件');
        return
      }
      let newArray = this.downLodaList;
      this.pageLoad = true;
      removeLoad(newArray).then(res => {
        if (res.data.code == '0') {
          this.$message.success('删除成功');
          this.pageLoad = false;
          this.dataLoad()
          this.downLodaListOld = []
          this.checkAll = false
        }else {
          this.$message.warning(res.data.msg);
          this.pageLoad = false;
        }

      })
    },
    // 删除文档
    deletWord(index, item) {
      var newArray = []
      newArray.push(item.idStr);
      let data = newArray;
      this.pageLoad = true;
      removeLoad(data).then(res => {
        if (res.data.code == '0') {
          this.$message.success('删除成功');
          this.pageLoad = false;
        } else {
          this.$message.warning(res.data.msg);
        }
        this.dataLoad()
      })
    },
    sizeChange(data) {
      this.chosetimeshow = true;
      this.siez = data;
    },
    sizeChanges(data) {
      this.chosetimeshow = true;
      if (data == 4) {
        this.fun_date(-7);
        this.times = '最近一周';
      } else {
        this.times = '最近一个月';
        this.fun_date(-30);
      }
      this.dataLoad();
      this.siezs = data;
    },
    onChange(id, name, filePath, item) {
      let newObj = {
        id,
        name,
        filePath
      }
      var index = this.downLodaList.indexOf(id)
      if (index == -1) {
        this.downLodaListOld.push(newObj);
        this.downLodaList.push(id);
        item.checked = true;
        this.$forceUpdate()
      } else {
        item.checked = false;
        this.$forceUpdate()
        this.downLodaListOld.splice(index, 1);
        this.downLodaList.splice(index, 1);
      }
    },
    onSearch() {
      this.loadhave = true;
      this.obj.pageNum = 1;
      this.dataLoad();
    },
    handleTimeChange(e, data) {
      this.siezs = 8;
      this.obj.createdDateStart = data[0];
      this.obj.createdDateEnd = data[1];
      this.times = data[0] + data[1];
      this.dataLoad();
    }
  },
};