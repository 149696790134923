/*
 * @Author: sqk
 * @Date: 2020-08-13 09:22:01
 * @LastEditTime: 2021-01-27 19:47:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\home\components\util.js
 */

export default {
  windowInitList: {
    msg: "",
    code: 0,
    systemConfigForm: {
      id: 14177072476,
      formCode: "loadCenter",
      formDocID: "11",
      formName: "下载中心查询条件",
      formType: "query",
      queryURL: "/",
      saveURL: "/",
      paramList: [
        {
          id: 14177072409,
          inputCode: "G11",
          formCode: "loadCenter",
          inputDocID: "G11",
          name: "文档类型",
          field: "type",
          inputType: "checkbox",
          fieldType: "String",
          logic: "=",
          value: null,
          parentInput: "",
          dictURL: "",
          dictType: "1",
          dictCode: "G11",
          onChangeEnven: null,
          maxLength: null,
          canNull: "Y",
          inputClass: "",
          orderNo: 0,
          dictList: [
            { code: "1", name: "文档", desc: null },
            { code: "2", name: "图片", desc: null },
            { code: "3", name: "其他", desc: null },
          ],
        },
        {
          id: 14177072410,
          inputCode: "",
          formCode: "loadCenter",
          inputDocID: "",
          name: "时间",
          field: "timeFrame",
          inputType: "dateGroup",
          fieldType: "String",
          logic: "=",
          value: null,
          parentInput: "",
          dictURL: null,
          dictType: "",
          dictCode: "G11",
          onChangeEnven: null,
          maxLength: null,
          canNull: "Y",
          inputClass: "",
          orderNo: 0,
          dictList: [
            { code: "1", name: "最近一周", desc: null },
            { code: "2", name: "最近一个月", desc: null },
          ],
        },
      ],
    },
  },
};
