/*
 * @Author: sqk
 * @Date: 2020-08-07 16:16:59
 * @LastEditTime: 2020-11-18 09:00:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\utils\api.js
 */
import http from "@/utils/request";
import pangeahttp from "@/utils/pangearequest"




export function downLoad(params) {
  return http({
    // url: '/fileRecords/list',
    url: '/loadCenter/fileRecords.nd',
    method: 'get',
    params
  })
}

export function removeLoad(data) {
  return http({
    url: '/loadCenter/remove.nd',
    method: 'delete',
    data
  })
}


export function loadChange(data) {
  return http({
    url: '/custbaseExportCenter/exportCenter.nd',
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    responseType: 'blob',
    method: 'post',
    data
  })
}